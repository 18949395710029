<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div class="body-container pb-16">
      <v-container
        v-if="quotation && !loading"
        class="px-10 px-md-auto px-lg-10"
      >
        <div class="text-h1 font-weight-black mb-5">Pagar</div>
        <div class="mb-10">
          <div class="text-h2 font-weight-bold">
            {{ quotation.service.name }}
          </div>
          <div class="d-flex justify-space-between text-h4">
            <div>
              {{ quotation.provider.name }}
            </div>
            <div class="d-flex justify-end">
              <a
                class="text-h4 font-weight-bold text-decoration-underline colorHover"
                style="color: inherit"
                :href="quotation.quotationRequest.quotationUrl"
                download
              >
                Ver cotización
              </a>
            </div>
          </div>
        </div>
        <div
          :class="quotation.quotationRequest.status !== 'completed' && 'pb-10'"
        >
          <!-- Mobile Version -->
          <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
            <div class="d-flex justify-center flex-column my-2">
              <div class="d-flex justify-center font-weight-bold text-h4 pb-3">
                Anticipo
              </div>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Fecha límite de pago</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>
                    {{ dateFormatter(quotation.quotationRequest.validUntil) }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Fecha de pago</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>{{ advanceDate }}</div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Porcentaje</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>{{ quotation.quotationRequest.paymentAdvance }}%</div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Monto</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>
                    {{
                      numberToCurrency(
                        (quotation.quotationRequest.totalPayment *
                          quotation.quotationRequest.paymentAdvance) /
                          100
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="quotation.quotationRequest.status == 'reservation'">
              <div
                v-for="(pay, index) in quotation.quotationRequest.reservation
                  .payment"
                :key="'payment-' + index"
                class="d-flex justify-center flex-column my-6"
              >
                <div
                  class="d-flex justify-center font-weight-bold text-h4 pb-3"
                >
                  Pago {{ index + 1 }}
                </div>
                <v-row class="text-body-1" no-gutters>
                  <v-col class="d-flex pr-2 justify-sm-end">
                    <div>Fecha límite de pago</div>
                  </v-col>
                  <v-col class="d-flex pl-2 justify-end justify-sm-start">
                    <div>-</div>
                  </v-col>
                </v-row>
                <v-row class="text-body-1" no-gutters>
                  <v-col class="d-flex pr-2 justify-sm-end">
                    <div>Fecha de pago</div>
                  </v-col>
                  <v-col class="d-flex pl-2 justify-end justify-sm-start">
                    <div>
                      {{ dateFormatter(pay.date) }}
                    </div>
                  </v-col>
                </v-row>
                <v-row class="text-body-1" no-gutters>
                  <v-col class="d-flex pr-2 justify-sm-end">
                    <div>Porcentaje</div>
                  </v-col>
                  <v-col class="d-flex pl-2 justify-end justify-sm-start">
                    <div>
                      {{ parseFloat(getPercent(pay.total).toFixed(2)) }}%
                    </div>
                  </v-col>
                </v-row>
                <v-row class="text-body-1" no-gutters>
                  <v-col class="d-flex pr-2 justify-sm-end">
                    <div>Monto</div>
                  </v-col>
                  <v-col class="d-flex pl-2 justify-end justify-sm-start">
                    <div>
                      {{ numberToCurrency(pay.total) }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="d-flex justify-center flex-column my-6">
              <div class="d-flex justify-center font-weight-bold text-h4 pb-3">
                Pendiente por liquidar
              </div>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Fecha límite de pago</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>
                    {{
                      quotation.quotationRequest.paymentAdvance == "100"
                        ? "-"
                        : dateFormatter(
                            quotation.quotationRequest.paymentDeadline
                          )
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Fecha de pago</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>-</div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Porcentaje</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div
                    v-if="
                      quotation.quotationRequest.status !== 'reservation' ||
                      quotation.quotationRequest.reservation.status !==
                        'confirmed'
                    "
                  >
                    {{ 100 - quotation.quotationRequest.paymentAdvance }}%
                  </div>
                  <div v-else>
                    {{
                      parseFloat(
                        (
                          100 -
                          getPercent(
                            quotation.quotationRequest.reservation.totalPaid
                          )
                        ).toFixed(2)
                      )
                    }}%
                  </div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Monto</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>
                    {{ numberToCurrency(getAmountToSettle()) }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div class="font-weight-bold">Total</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div class="font-weight-bold">
                    {{
                      numberToCurrency(quotation.quotationRequest.totalPayment)
                    }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Desktop Version -->
          <div v-else>
            <v-row>
              <v-col cols="2"> </v-col>
              <v-col class="border-divider" cols="3">
                <div
                  class="d-flex justify-center text-center text-h3"
                  style="line-height: normal"
                >
                  Fecha límite de pago
                </div>
              </v-col>
              <v-col class="border-divider" cols="3" lg="2">
                <div
                  class="d-flex justify-center text-center text-h3"
                  style="line-height: normal"
                >
                  Fecha de pago
                </div>
              </v-col>
              <v-col class="border-divider" cols="1">
                <div
                  class="d-flex justify-center text-center text-h3"
                  style="line-height: normal"
                >
                  Porcentaje
                </div>
              </v-col>
              <v-col class="border-divider" cols="2">
                <div
                  class="d-flex justify-center text-center text-h3"
                  style="line-height: normal"
                >
                  Monto
                </div>
              </v-col>
              <v-col class="border-divider" cols="1" lg="2">
                <div
                  class="d-flex justify-center text-center text-h3"
                  style="line-height: normal"
                >
                  Total
                </div>
              </v-col>
            </v-row>

            <div class="my-2 text-h4">
              <v-row>
                <v-col cols="2">
                  <div class="font-weight-bold">Anticipo</div>
                </v-col>
                <v-col cols="3">
                  <div class="d-flex justify-center">
                    {{ dateFormatter(quotation.quotationRequest.validUntil) }}
                  </div>
                </v-col>
                <v-col cols="3" lg="2">
                  <div class="d-flex justify-center">
                    {{ advanceDate }}
                  </div>
                </v-col>
                <v-col cols="1">
                  <div class="d-flex justify-center">
                    {{ quotation.quotationRequest.paymentAdvance }}%
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="d-flex justify-center">
                    {{
                      numberToCurrency(
                        (quotation.quotationRequest.totalPayment *
                          quotation.quotationRequest.paymentAdvance) /
                          100
                      )
                    }}
                  </div>
                </v-col>
                <v-col cols="1" lg="2"> </v-col>
              </v-row>
            </div>

            <div
              v-if="quotation.quotationRequest.status == 'reservation'"
              class="my-2 text-h4"
            >
              <v-row
                v-for="(pay, index) in quotation.quotationRequest.reservation
                  .payment"
                :key="'payment-' + index"
              >
                <v-col cols="2">
                  <div class="font-weight-bold">Pago {{ index + 1 }}</div>
                </v-col>
                <v-col cols="3">
                  <div class="d-flex justify-center">-</div>
                </v-col>
                <v-col cols="3" lg="2">
                  <div class="d-flex justify-center">
                    {{ dateFormatter(pay.date) }}
                  </div>
                </v-col>
                <v-col cols="1">
                  <div class="d-flex justify-center">
                    {{ parseFloat(getPercent(pay.total).toFixed(2)) }}%
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="d-flex justify-center">
                    {{ numberToCurrency(pay.total) }}
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-row class="text-h4">
              <v-col cols="2">
                <div class="font-weight-bold">Pendiente por liquidar</div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex justify-center">
                  {{
                    quotation.quotationRequest.paymentAdvance == "100"
                      ? "-"
                      : dateFormatter(
                          quotation.quotationRequest.paymentDeadline
                        )
                  }}
                </div>
              </v-col>
              <v-col cols="3" lg="2">
                <div class="d-flex justify-center">-</div>
              </v-col>
              <v-col cols="1">
                <div
                  v-if="
                    quotation.quotationRequest.status !== 'reservation' ||
                    quotation.quotationRequest.reservation.status !==
                      'confirmed'
                  "
                  class="d-flex justify-center"
                >
                  {{ 100 - quotation.quotationRequest.paymentAdvance }}%
                </div>
                <div v-else class="d-flex justify-center">
                  {{
                    parseFloat(
                      (
                        100 -
                        getPercent(
                          quotation.quotationRequest.reservation.totalPaid
                        )
                      ).toFixed(2)
                    )
                  }}%
                </div>
              </v-col>
              <v-col cols="2">
                <div class="d-flex justify-center">
                  {{ numberToCurrency(getAmountToSettle()) }}
                </div>
              </v-col>
              <v-col cols="1" lg="2">
                <div class="d-flex justify-center font-weight-bold">
                  {{
                    numberToCurrency(quotation.quotationRequest.totalPayment)
                  }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          v-if="quotation.quotationRequest.status !== 'completed'"
          class="mt-16 text-center text-sm-start"
        >
          <div class="text-h3 font-weight-bold mb-4">
            Realizar pago
            {{
              quotation.quotationRequest.status !== "reservation"
                ? "de anticipo"
                : ""
            }}
          </div>
          <div class="d-flex justify-center justify-sm-start mb-7 text-h4">
            <v-radio-group
              v-model="paymentMethod"
              mandatory
              :column="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              hide-details
              @change="handleMaxOxxoPayment"
            >
              <v-radio
                value="card"
                :ripple="false"
                color="secondary"
                class="mr-md-16 mb-3 mb-md-0"
              >
                <template v-slot:label>
                  <span class="black--text mr-5">Pago con tarjeta</span>
                  <v-img
                    max-height="40px"
                    max-width="125px"
                    src="@/assets/icons/CardsPayment.svg"
                  />
                </template>
              </v-radio>
              <v-radio
                v-if="
                  quotation.quotationRequest.status == 'reservation' ||
                  parseFloat(
                    (quotation.quotationRequest.totalPayment *
                      quotation.quotationRequest.paymentAdvance) /
                      100
                  ) < 10000.0
                "
                value="oxxo"
                :ripple="false"
                color="secondary"
                class="ml-md-10"
              >
                <template v-slot:label>
                  <span class="black--text mr-5">
                    Pago en establecimientos OXXO
                  </span>
                  <v-img
                    max-height="40px"
                    max-width="62px"
                    src="@/assets/icons/OxxoPayment.svg"
                  />
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <v-form
            v-if="quotation.quotationRequest.status == 'reservation'"
            ref="reservationPaymentForm"
            v-model="valid"
            lazy-validation
          >
            <div
              class="d-flex flex-column flex-md-row align-center align-sm-start align-md-baseline text-h4"
            >
              <span class="mb-2">
                Realizar pago por la siguiente cantidad
              </span>
              <div class="d-flex align-baseline flex-wrap flex-sm-nowrap">
                <div
                  :style="
                    $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 166px'
                  "
                  class="mr-0 mr-sm-2 mx-md-6 mt-2 mt-md-0 mb-3 mb-sm-0"
                >
                  <v-text-field
                    v-model="paymentAmount"
                    flat
                    dense
                    solo
                    outlined
                    type="number"
                    pattern="^[0-9]+"
                    min="0"
                    step="1"
                    :height="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 47
                    "
                    color="secondary"
                    class="rounded-lg v-input--is-focused"
                    placeholder="0.00"
                    :rules="moneyRules"
                    :hide-details="$vuetify.breakpoint.xs && 'auto'"
                    oninput="if(this.value < 0) this.value = '';"
                    @keypress.enter.prevent="handleReservationPayment"
                    @input="handleQuantityFormat"
                  >
                    <template slot="prepend-inner">
                      <span
                        class="mr-n1 black--text"
                        :class="!paymentAmount && 'text--disabled'"
                      >
                        $
                      </span>
                    </template>
                  </v-text-field>
                </div>
                <Button
                  text="Pagar"
                  :block="$vuetify.breakpoint.xs"
                  :disabled="loadingPayment"
                  aria-label="Realizar pago"
                  @event="handleReservationPayment()"
                />
              </div>
            </div>
            <v-scroll-y-transition hide-on-leave>
              <div
                v-if="minPaymentUnreached"
                class="font-italic mt-2 mt-sm-0 mb-2 mb-sm-5 red--text"
              >
                El monto mínimo para un pago es de $15.00. Consulta más detalles
                en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                >
                  este enlace </router-link
                >.
              </div>
              <div
                v-if="totalExceeded"
                class="font-italic mt-2 mt-sm-0 mb-2 mb-sm-5 red--text"
              >
                El monto a pagar debe ser menor o igual a la cantidad pendiente
                por liquidar
              </div>
              <div
                v-else-if="insufficientResidual"
                class="font-italic mt-2 mt-sm-0 mb-2 mb-sm-5 red--text"
              >
                El monto restante después del pago debe ser mayor a $14.99.
                Consulta más detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                >
                  este enlace </router-link
                >.
              </div>
              <div
                v-else-if="maxPaymentExceeded"
                class="font-italic mt-2 mt-sm-0 mb-2 mb-sm-5 red--text"
              >
                El monto máximo para un pago es de $999,999.99. Consulta más
                detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                >
                  este enlace </router-link
                >.
              </div>
              <div
                v-else-if="maxOxxoPaymentExceeded"
                class="font-italic mt-2 mt-sm-0 mb-2 mb-sm-5 red--text"
              >
                El monto máximo para un pago en establecimientos OXXO es de
                $10,000.00. Consulta más detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                  >este enlace</router-link
                >.
              </div>
            </v-scroll-y-transition>
          </v-form>
          <div
            class="d-flex flex-column flex-sm-row align-center mb-3 mb-sm-9 pt-5"
          >
            <v-card
              elevation="0"
              height="73px"
              class="d-flex align-center px-5 mr-0 mr-sm-5 mt-8 mt-sm-0 rounded-lg blue-border order-2 order-sm-1"
            >
              Pago gestionado por
              <img
                :width="$vuetify.breakpoint.xs ? '60px' : '67px'"
                style="pointer-events: none"
                src="@/assets/icons/providers/StripeLogo.svg"
              />
            </v-card>
            <Button
              v-if="quotation.quotationRequest.status !== 'reservation'"
              text="Pagar"
              :block="$vuetify.breakpoint.xs"
              :disabled="loadingPayment"
              class="ml-sm-4 order-1 order-sm-2"
              aria-label="Realizar pago"
              @event="handleAdvancePayment()"
            />
          </div>
          <div class="text-h4">
            Conoce más sobre
            <a
              href="https://stripe.com/es/payments"
              target="_blank"
              rel="noopener noreferrer"
              class="font-weight-bold"
              style="color: inherit"
            >
              Stripe
            </a>
          </div>
        </div>
      </v-container>
      <v-container
        v-else-if="loading"
        class="d-flex flex-column align-div-content align-center"
      >
        <v-progress-circular
          indeterminate
          color="secondary"
          :size="100"
        ></v-progress-circular>
      </v-container>
    </div>
    <ModalLoading :dialog="loadingPayment" />
    <ModalPaymentDeclined
      :dialog="this.$route.query.transaccion_incompleta == 'true'"
      @closeModal="handlePaymentDeclinedModal"
    />
    <div :class="quotation.quotationRequest.status !== 'completed' && 'pt-16'">
      <Footer />
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";
import ModalPaymentDeclined from "@/components/User/Payments/ModalPaymentDeclined.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { mapState } from "vuex";
import { moneyRules } from "@/Utils/rules.js";
import { useQuery } from "@/graphql/index";
import {
  GET_QUOTATION_PAYMENT_DATA,
  GET_STRIPE_CHECKOUT_SESSION,
} from "@/graphql/queries";
import { useMutation } from "@/graphql/index";
import { CREATE_PAYMENT, UPDATE_PAYMENT } from "@/graphql/mutations";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter.js";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  name: "Payment",
  components: {
    Navbar,
    UserNavbar,
    SearchBar,
    Button,
    Footer,
    ModalPaymentDeclined,
    ModalLoading,
  },
  data() {
    return {
      valid: null,
      paymentAmount: null,
      moneyRules: moneyRules,
      quotation: {
        provider: {
          name: "",
        },
        service: {
          id: null,
          name: "",
        },
        quotationRequest: {
          quotationUrl: "",
          reservation: {
            payment: [],
          },
        },
      },
      loading: true,
      loadingPayment: false,
      paymentFetching: false,
      paymentFetched: false,
      advanceDate: "-",
      minPaymentUnreached: false,
      totalExceeded: false,
      insufficientResidual: false,
      maxPaymentExceeded: false,
      maxOxxoPaymentExceeded: false,
      paymentMethod: "card",
    };
  },
  computed: mapState({
    user(state) {
      return state.user;
    },
  }),
  watch: {
    user: {
      async handler(user) {
        if (!user || !user.id) return;
        if (!this.paymentFetching && !this.paymentFetched) {
          this.paymentFetching = true;
          await this.fetchPayment();
          this.paymentFetched = true;
          this.paymentFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchPayment() {
      const props = {
        quotationId: parseInt(this.$route.params.id),
        clientId: parseInt(this.user.id),
        route: "user",
      };

      try {
        const { data, errors } = await useQuery(
          GET_QUOTATION_PAYMENT_DATA,
          props
        );

        if (errors) throw errors;

        this.quotation = data.quotation;
        if (
          (this.quotation.quotationRequest.status !== "approved" &&
            this.quotation.quotationRequest.status !== "reservation") ||
          (this.quotation.quotationRequest.reservation &&
            this.quotation.quotationRequest.reservation.status !==
              "confirmed" &&
            this.quotation.quotationRequest.reservation.status !== "pending")
        ) {
          this.$router.push({ name: "UserPayments" });
        }
        if (this.quotation.quotationRequest.reservation) {
          if (
            this.quotation.quotationRequest.reservation.status !== "pending"
          ) {
            this.advanceDate = this.dateFormatter(
              this.quotation.quotationRequest.reservation.payment.shift().date
            );
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "UserPayments" });
      }
    },
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    getAmountToSettle() {
      const totalPayment = this.quotation.quotationRequest.totalPayment;
      const totalPaid =
        this.quotation.quotationRequest.status !== "reservation" ||
        this.quotation.quotationRequest.reservation.status !== "confirmed"
          ? (this.quotation.quotationRequest.totalPayment *
              this.quotation.quotationRequest.paymentAdvance) /
            100
          : this.quotation.quotationRequest.reservation.totalPaid;
      return parseFloat(totalPayment - totalPaid).toFixed(2);
    },
    getPercent(pay) {
      return (pay / this.quotation.quotationRequest.totalPayment) * 100;
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
    handlePaymentDeclinedModal() {
      this.$router.push({
        name: "UserPayment",
        params: { id: this.$route.params.id },
      });
    },
    isSettlementPayment() {
      return this.getAmountToSettle() === parseFloat(this.paymentAmount);
    },
    async handleQuantityFormat() {
      await this.$nextTick();
      this.paymentAmount = this.paymentAmount.replace(
        /^(?:0+(?=[1-9])|0+(?=0$))/gm,
        ""
      );
      this.paymentAmount = this.paymentAmount.replace(/(?<=\.[0-9]{2}).+/g, "");
      this.handleTotal();
      this.handleMaxPayment();
      this.handleMaxOxxoPayment();
    },
    handleTotal() {
      this.totalExceeded =
        parseFloat(this.paymentAmount) > this.getAmountToSettle();
    },
    handleMaxPayment() {
      this.maxPaymentExceeded = parseFloat(this.paymentAmount) > 999999.99;
    },
    handleMaxOxxoPayment() {
      this.maxOxxoPaymentExceeded =
        this.quotation.quotationRequest.status == "reservation" &&
        this.paymentMethod == "oxxo" &&
        parseFloat(this.paymentAmount) > 10000.0;
    },
    handleMinPayment() {
      this.minPaymentUnreached = parseFloat(this.paymentAmount) < 15.0;
    },
    handleResidual() {
      if (!this.isSettlementPayment(parseFloat(this.paymentAmount))) {
        const residual =
          this.getAmountToSettle() - parseFloat(this.paymentAmount);
        this.insufficientResidual = residual > 0.0 && residual < 15.0;
      }
    },
    async redirectToStripe(cancelUrl, successUrl, paymentId, payment) {
      const { data, errors } = await useQuery(GET_STRIPE_CHECKOUT_SESSION, {
        payment: parseFloat(payment),
        serviceId: parseInt(this.quotation.service.id),
        successUrl: successUrl,
        cancelUrl: cancelUrl,
        customerEmail: this.user.email,
        paymentMethod: this.paymentMethod,
      });
      if (data) {
        const updatePayment = await useMutation(UPDATE_PAYMENT, {
          paymentId: paymentId,
          checkoutSessionId: data.getStripeCheckoutSession.id,
        });
        if (updatePayment.data) {
          window.location.href = data.getStripeCheckoutSession.url;
        }
      } else if (errors) {
        console.log(errors);
        this.$store.dispatch("handleAlert", {
          type: "error",
          message: "Error al ingresar a Stripe, por favor intente más tarde",
        });
      }
    },
    async handleAdvancePayment() {
      this.loadingPayment = true;
      const advancePaymentAmount = (
        (this.quotation.quotationRequest.totalPayment *
          this.quotation.quotationRequest.paymentAdvance) /
        100
      ).toFixed(2);
      const idQuotation = parseInt(this.$route.params.id);
      const provisionalPayment = await useMutation(CREATE_PAYMENT, {
        quotationRequestId: idQuotation,
        total: parseFloat(advancePaymentAmount),
      });
      if (provisionalPayment.data) {
        const paymentId = provisionalPayment.data.createPayment.payment[0].id;
        const successUrl = `${process.env.VUE_APP_STRIPE_URL}usuario/pagos/${idQuotation}/${paymentId}/anticipo_exitoso`;
        const cancelUrl = `${process.env.VUE_APP_STRIPE_URL}usuario/pagos/${idQuotation}?transaccion_incompleta=true`;
        await this.redirectToStripe(
          cancelUrl,
          successUrl,
          paymentId,
          advancePaymentAmount
        );
      }
      this.loadingPayment = false;
    },
    async handleReservationPayment() {
      this.handleMinPayment();
      this.handleMaxPayment();
      this.handleMaxOxxoPayment();
      this.handleTotal();
      this.handleResidual();
      if (
        this.$refs.reservationPaymentForm.validate() &&
        !this.totalExceeded &&
        !this.maxPaymentExceeded &&
        !this.maxOxxoPaymentExceeded &&
        !this.minPaymentUnreached &&
        !this.insufficientResidual
      ) {
        this.loadingPayment = true;
        const idQuotation = parseInt(this.$route.params.id);
        const provisionalPayment = await useMutation(CREATE_PAYMENT, {
          quotationRequestId: idQuotation,
          total: parseFloat(this.paymentAmount),
        });
        if (provisionalPayment.data) {
          const paymentId = provisionalPayment.data.createPayment.payment[0].id;
          let successUrl = `${process.env.VUE_APP_STRIPE_URL}usuario/pagos/${idQuotation}/${paymentId}/`;
          if (this.isSettlementPayment()) {
            successUrl += "liquidacion_exitosa";
          } else {
            successUrl += "abono_exitoso";
          }
          const cancelUrl = `${process.env.VUE_APP_STRIPE_URL}usuario/pagos/${idQuotation}?transaccion_incompleta=true`;
          await this.redirectToStripe(
            cancelUrl,
            successUrl,
            paymentId,
            this.paymentAmount
          );
        }
        this.loadingPayment = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.blue-border {
  border: 1px solid var(--v-secondary-base);
}
.border-divider {
  border-bottom: 1px solid #21145f;
}
.align-div-content {
  display: flex;
  justify-content: center;
  height: 40vh;
}
</style>
